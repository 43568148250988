import React from 'react'
import Grid from '@material-ui/core/Grid'
import {Link} from 'gatsby'


import HomeMade from "../../assets/images/sparkle-logos/homemade.webp"
import Express from "../../assets/images/sparkle-logos/spark-express.webp"
import Paws from "../../assets/images/sparkle-logos/paws.webp"
import Sparkle247 from "../../assets/images/sparkle-logos/sparkle-24-7.webp"
import Fresh from "../../assets/images/sparkle-logos/fresh.webp"
import Electronics from "../../assets/images/sparkle-logos/electronics.webp"

import  makeStyles  from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      marginLeft: "0em",
      width: "100%",
      marginTop: "1em", 
      marginBottom: "1em"
    },
  }))

  

const SparkleServices = () =>{
    const classes = useStyles()
    return  <div
    className="menu"
    style={{ overflowX: "hidden", overflowY: "hidden" }}
  >
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={4}>
        <Link to="/food">
          <img
            src={HomeMade}
            alt="Home"
            loading='lazy'
            width={100}
            height = {100} />
        </Link>
        <div className="menu--text">Homemade</div>
      </Grid>
      <Grid item xs={4}>
        <Link to="/express">
          <img
            src={Express}
            alt="Express"
            effect="blur"
            loading = 'lazy'
            width = {100}
            height = {100}
          />
        </Link>
        <div className="menu--text">Express Delivery</div>
      </Grid>
      <Grid item xs={4}>
        <Link to="/shop/petaholic--6311979f7cc0c70016c8ebe1">
          <img
            src={Paws}
            alt="Paws"
            loading = 'lazy'
            effect="blur"
            width= {100}
            height ={100}
          />
        </Link>
        <div className="menu--text">Sparkle Pets</div>
      </Grid>
    </Grid>
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={4} style={{ opacity: "0.5" }}>
        <Link>
          <img
            src={Sparkle247}
            loading = 'lazy'
            alt="Sparkle 24/7"
            effect="blur"
            width="100px"
            height = "100px"
          />
        </Link>
        <div className="menu--text">Sparkle 24/7</div>
      </Grid>
      <Grid item xs={4} style={{ opacity: "0.5" }}>
        <Link>
          <img
            src={Fresh}
            loading = 'lazy'
            alt="Fresh"
            effect="blur"
            width="100px"
            height = "100px"
          />
        </Link>
        <div className="menu--text">Fresh</div>
      </Grid>
      <Grid item xs={4} style={{ opacity: "0.5" }}>
        <Link>
          <img
            src={Electronics}
            loading = "lazy"
            alt="Electronics"
            effect="blur"
            width="100px"
            height = "100px"
          />
        </Link>
        <div className="menu--text">Electronics</div>
      </Grid>
    </Grid>
  </div>
}

export default SparkleServices